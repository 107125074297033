<template>
	<div class="backdrop">
	  <div class="b-modal" id="modal-scrollable" scrollable>
		<div id="modalHeader" class="text-white text-center"> 
      
			<div>
			</div>
			<h5 v-if="!isNft">
				CREATE TOKEN ASSET
			</h5> 
			<h5 v-else>
				CREATE NFT ASSET
			</h5> 
			<div></div>

		</div>
		<div class="modalBody text-center" v-if="state == 'send'">
			<p class="text-white">SEND CXT</p>
			<input class="form-control input-send" type="text" v-model="blxAmount" readonly>
			<p class="error">{{ errors.noBlx }}</p>
			<button class="btn cancel" @click="sendBlx">Send</button>
		</div>
		<div class="modalBody text-center" v-if="state == 'otp'">
			<form  @submit.prevent="verifyOtp">
		
				<p class="text-center text-white">Enter the one-time PIN you received:</p>
				<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
				<div class="d-flex justify-content-center">
					<div class="otp-wrapper" >
					<div>
						<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					</div>
					</div>
				</div>
				<div class="text-center text-white">
					<p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
					<p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
				</div>
		
				<div class="form-group">
					<button type="submit" class="btn cancel">Submit</button>
				</div>
			</form>
		</div>
		<div class="modalBody text-center text-white" v-if="state == 'key'">
			<h5 class="card-text">IMPORTANT</h5>
			<p class="card-subtext">This is your Asset Agreement Encryption Key (AKA Smart Contract Hash ID)</p>
			<p class="card-subtext">Please copy and secure your ASSET KEY below immediately</p>
			<div class="d-flex mb-3">
				<input type="text" class="form-control input-copy" v-model="assetKey" readonly ref="assetKey">
				<button class="btn copy" @click="copyKey">Tap to Copy</button>
			</div>
			<p v-if="isCopied" style="color:green;">Asset key is copied</p>
			<button class="btn cancel" @click="copiedKey">Done</button>
		</div>
		<div class="modalBody text-center text-white" v-if="state == 'create'">
			<form action="" class="form-group">
				<b-button v-if="!isDoneAssetCreation" class="btn cancel" @click.prevent="toggleConfigModal('open')">Upload config file</b-button>
				<hr v-if="!isDoneAssetCreation">
				<div class="text-center" v-if="!isDoneAssetCreation">
				  <p>or</p>
				</div>
				<hr v-if="!isDoneAssetCreation">
					<div>
						<label for="">Asset name</label>
						<input type="text" class="form-control" placeholder="Token Asset Name" maxlength="32" v-model="assetInfo.name" :disabled="isFieldDisabled" v-if="!isNft">
						<input type="text" class="form-control" placeholder="NFT Asset Name" maxlength="32" v-model="assetInfo.name" :disabled="isFieldDisabled" v-if="isNft">
						<p class="error">{{ errors.noName }}</p>

						<label for="">Symbol / Ticker</label>
						<input type="text" class="form-control" maxlength="6"  placeholder="6 characters" v-model="assetInfo.ticker" :disabled="isFieldDisabled"> 
						<p class="error">{{ errors.noTicker }}</p>

						<div v-if="!isNft">
							<label for="">Circulating Supply</label>
							<input type="text" class="form-control"  placeholder="Maximum limit 10B" v-model="assetInfo.supply" maxlength="11" :disabled="isFieldDisabled">
						</div>
						<div v-if="isNft">
							<label>Value in CXT</label>
							<input type="text" class="form-control" maxlength="34" v-model="assetInfo.value" :disabled="isFieldDisabled">
						</div>
						<p class="error">{{ errors.noSupply }}</p>
					</div>
					<div>
						<label for>Description</label>
						<textarea type="text" class="form-control" maxlength="150"  placeholder="150 characters" v-model="assetInfo.desc" :disabled="isFieldDisabled"></textarea>
						<p class="error">{{ errors.noDesc }}</p>

						<input type="text" class="form-control"  placeholder="34 characters" v-model="assetInfo.masterWalletAddress" disabled>
						<p class="error">{{ errors.noMasterWallet }}</p>
					</div>
				<div class="text-center text-white p-3" style="font-size:12px;">IMPORTANT: SECURE AND SAVE IMMEDIATELY TO PREVENT UNAUTHORIZED ACCESS! DO NOT LOSE AND DO NOT SHARE YOUR PRIVATE KEYS!</div>
				<div class="mt-2" v-if="showUploadBtn">
					<label for="">Icon</label>
					<button type="button" class="btn mb-3 upload" @click.prevent="toggleImgModal" v-if="!isNft">Upload image. Max size: 2MB. (.jpg, .png)</button>
					<button type="button" class="btn mb-3 upload" @click.prevent="toggleImgModal" v-if="isNft">Upload image. Max size: 10MB. (.jpg, .png, .mp3, .mp4)</button>
				</div>
				<button  v-if="!isDoneAssetCreation" class="btn cancel" type="submit" @click.prevent="submitForm">Submit</button>
			  </form>
		</div>
		<div class="modalBody text-center text-white" v-if="state == 'confirm'">
			<p class="card-subtext">Enter your Asset Key to activate your smart contract</p>
			<input type="text" class="form-control" v-model="hashKey">
			<p class="error">{{ errors.noHash }}</p>
			<button class="btn cancel" @click="enteredHash">Submit</button>
		</div>
		<div class="modalBody text-center text-white" v-if="state == 'success'">
			<h6>Your smart contract is now activated.</h6>
			<a :href="'https://test-explorer-cxt.coexstar.com/#/transaction/' + assetInfo.hash" target="_blank"><b-button class="btn-upload mt-2">{{ assetInfo.hash | truncate(40) }}</b-button></a>
			<b-button class="btn cancel mt-2" @click="reload">Done</b-button>
		</div>

		<div class="modalBody text-center">
			<button class="btn submit mt-2 mb-5" @click="closeModal">Cancel</button>
		</div>
	  </div>
		<UploadModal v-if="showImgModal" @close="toggleImgModal" @done="submitCompleteForm" :symbol="assetInfo.ticker"></UploadModal>
		<ConfigModal v-if="showConfigModal" @close="toggleConfigModal('close')" @done="toggleConfigModal('done')" :isNft="isNft"></ConfigModal>
		<UploadNft v-if="showNftModal" @close="toggleImgModal" @done="submitCompleteForm" :symbol="assetInfo.ticker"></UploadNft>
		<DoneModal v-if="showDone"></DoneModal>
	</div>
  </template>
  
  <script>
  import UploadModal from './uploadIcon.vue'
  import ConfigModal from './uploadTokenConfig.vue'
  import UploadNft from './uploadNft.vue'
  import DoneModal from './Done.vue'

  export default {
	  props:["isNft"],
	  components: {
		UploadModal,
		ConfigModal,
		DoneModal,
		UploadNft
	  },
	  data: function() {
		  return {
			blxAmount: 1000,
			state: 'send',
			showError: false,
			systemMsg: "",
			errors: {
				noBlx: "",
				noOtp: "",
				noName: "",
				noTicker: "",
				noSupply: "",
				noDesk: "",
				noMasterWallet: "",
				noHash: ""
			},
			otpArr: [],
			assetKey: "",
			assetInfo: {
				name: "",
				ticker: "",
				supply: "",
				desc: "",
				masterWalletAddress: "",
				value: 0,
				icon: null,
				hash: "",
			},
			hashKey: "",
			isDoneAssetCreation: false,
			isFieldDisabled: false,
			showUploadBtn: false,
			showImgModal: false,
			showNftModal: false,
			showConfigModal: false,
			showDone: false,
			ifResent: true,
			timerInSeconds: 60,
			isCopied: false
		  }
	  },
  
	  computed: {
		
	  },
  
	  mounted() {
	   
	  },
  
	  watch: {
		  
	  },
  
	  methods: {
		  closeModal(){
			  this.$emit("close")
		  },

		  getAddress: function(){
				const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					url: '/cx/address/CXT',
				};

				this.axios(options)
					.then((response) => {
						this.assetInfo.masterWalletAddress = response.data.addressValue
						console.log(this.assetInfo.masterWalletAddress)
					}).catch((err) => {
						
						console.log(err.response.data)     

						
					})
			},

		  reload() {
			location.reload();
		  },

		  resendCode: function() {
			const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
				url: '/cx/private/resend2fa',
			};

			this.axios(options)
				.then((response ) => { 
				this.systemMsg = ""
				this.showError = false
				this.ifResent = true
				this.otpArr = []
				console.log(this.ifResent)
				this.timer();
				}).catch((err) => {
					console.log('resend code errror')
					console.log(err)
				})
        	},

			timer() {
				let counterInterval = setInterval(() => {
					this.timerInSeconds--;
					// console.log(this.timerInSeconds)

					if(this.timerInSeconds == 0 || this.ifResent == false) {
						clearInterval(counterInterval)
						this.ifResent = false
						this.timerInSeconds = 60
					}
				}, 1000);
			},

			timeFormat(seconds) {
				let date = new Date(0);
				date.setSeconds(seconds);
				return date.toISOString().substr(14, 5);
			},

			isNumber: function(evt) {
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
				evt.preventDefault();
				} else {
				return true;
				}
			},
			otpBack: function(event, currrentBox) {
			
				if (event.keyCode === 8) {
				if (currrentBox != 1) {

					setTimeout(function() { 
						document.getElementById("otp"+ (currrentBox - 1)).focus();
					}, 50)

				}
			}

			if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

				setTimeout(function() { 

					if((currrentBox) < 6) {
						document.getElementById("otp"+ (currrentBox + 1)).focus();
					}
					
				}, 50)

				}
			},

			copyKey() {
				var Key = this.$refs.assetKey;
				Key.select();
				document.execCommand("copy");
				this.isCopied = true
			},

			sendBlx() {
				const params = {
					isNft: this.isNft,
					}

					const data = Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');

			const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					url: '/cx/smartcontract/send/init',
					data,
					};
					this.axios(options)
						.then((response) => {
							this.state = 'otp'
							this.showError = false
							this.errors.noBlx = ''
						})
						.catch((error) => {
							this.errors.noBlx = error.response.data.msgText
							if(error.response.data.msgText == 'You still have pending contract.') {
								this.state = 'otp'
							}
						});
			},

			verifyOtp() {
				const params = {
					otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
					isNft: this.isNft,
					}

					const data = Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');
			

					const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					data,
					url: '/cx/smartcontract/send/verify',
					};
					this.axios(options)
						.then((response) => {
							this.state = 'key'
							this.assetKey = response.data.assetKey
						})
						.catch((error) => {
							// Error code goes here
							this.systemMsg = "Incorrect OTP."
							this.showError = true
							this.otpArr = []
						});
			},

			copiedKey() {
				this.state = 'create'
			},

			submitForm() {
			if((this.assetInfo.masterWalletAddress != "") || (this.assetInfo.masterWalletAddress != null)) {
					const params = {
					assetName:   this.assetInfo.name,
					symbol: this.assetInfo.ticker,
					supply: this.assetInfo.supply,
					description: this.assetInfo.desc,
					masterWalletAddress: this.assetInfo.masterWalletAddress,
					value: this.assetInfo.value,
					isNft: this.isNft,
					}

					const data = Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');
			

					const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					data,
					url: '/cx/smartcontract/createasset',
					};
					this.axios(options)
						.then((response) => {
						// this.showSteps.three = false
						// this.showSteps.four = true
						// this.showCheck.three = true
						// this.isDisabled.four = false
						this.isFieldDisabled = true
						this.isDoneAssetCreation = true
						this.showUploadBtn = true
						this.errors.noName = ""
						this.errors.noTicker = ""
						this.errors.noSupply = ""
						this.errors.noDesc = ""
						this.errors.noMasterWallet = ""
						this.assetInfo.hash = response.data.hash
						this.checkContract();
						})
						.catch((error) => {
						// console.log(error.response.data.msgText)
						if ((this.assetInfo.name == "") || (this.assetInfo.name == null)) {
							this.errors.noName = "This is required."
						}

						if(error.response.data.msgText == "This Symbol/Ticker is not available.") {
							this.errors.noTicker = "This Symbol/Ticker is not available."
						} else if ((this.assetInfo.ticker == "") || (this.assetInfo.ticker == null)) {
							this.errors.noTicker = "This is required."
						} else if(error.response.data.msgText == "Symbol must not contain any digit.") {
							this.errors.noTicker = "Symbol must not contain any digit."
						} else if(error.response.data.msgText == "Symbol must not contain any special characters."){
							this.errors.noTicker = error.response.data.msgText
						}

						if(this.isNft == false) {
							if(error.response.data.msgText == "Max Supply is 10,000,000,000.") {
								this.errors.noSupply = ""
								this.errors.noSupply = "Max Supply is 10,000,000,000."
								console.log('b')
								console.log(this.errors.noSupply)
								console.log(this.assetInfo.supply)
							} else if ((this.assetInfo.supply == "") || (this.assetInfo.supply == null) || (this.assetInfo.supply == "")) {
								this.errors.noSupply = "This is required."
								console.log('a')
							} 
						} else if (this.isNft == true) {
							if ((this.assetInfo.value == "") || (this.assetInfo.value == null) || (this.assetInfo.value == 0)) {
								this.errors.noSupply = 'This is required.'
							}
						}

						if ((this.assetInfo.desc == "") || (this.assetInfo.desc == null)) {
							this.errors.noDesc = "This is required."
						}

						if ((this.assetInfo.masterWalletAddress == "") || (this.assetInfo.masterWalletAddress == null)) {
							this.errors.noMasterWallet = "This is required."
						}

						setTimeout( () => {
							this.errors.noName = ""
							this.errors.noTicker = ""
							this.errors.noSupply = ""
							this.errors.noDesc = ""
							this.errors.noMasterWallet = ""
						},5000)
						});
					} else {
						this.errors.noMasterWallet = "This is required."
					}
			},

			toggleConfigModal(todo) {
				if(todo == "open") {
				this.showConfigModal = true
				} else if(todo == "close") {
				this.showConfigModal = false
				} else if(todo == "done") {
				this.checkContract()
				}
			},

			toggleImgModal() {
				if(this.isNft == false) {
					this.showImgModal = !this.showImgModal
					console.log('.')
				} else if(this.isNft == true) {
					this.showNftModal = !this.showNftModal
					console.log('..')
				} 
			},

			submitCompleteForm() {
				this.state = 'confirm'
			},

			enteredHash() {
				const params = {
					assetKey: this.hashKey,
					symbol: this.assetInfo.ticker
					}

					const data = Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');
			

					const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					data,
					url: '/cx/smartcontract/activate',
					};
					this.axios(options)
						.then((response) => {
							this.state = 'success'
							console.log(this.state)
						})
						.catch((error) => {
						this.errors.noHash = "Invalid asset key."
						});
        	},

			checkContract() {

					const options = {
						method: 'GET',
						url: '/cx/smartcontract/currentstep?isNft=' + this.isNft,
					};
					this.axios(options)
						.then((response) => {
							if(response.data.assetKey != null) {
								this.state = 'key'
								if(response.data.symbol == null) {
									this.state = 'create'
								}
								else if(response.data.symbol != null) {
									this.assetKey = response.data.assetKey
									this.assetInfo.name = response.data.assetName
									this.assetInfo.ticker = response.data.symbol
									this.assetInfo.desc = response.data.description
									this.assetInfo.supply = response.data.supply
									this.assetInfo.masterWalletAddress = response.data.masterWalletAddress
									this.assetInfo.value = response.data.value
									this.assetInfo.hash = response.data.hash
									this.isFieldDisabled = true
									this.state = 'create'
									this.showUploadBtn = true
									this.isDoneAssetCreation = true
									if(response.data.icon != null  && this.isNft == false || response.data.media != null && this.isNft == true) {
										this.state = 'confirm'
										this.assetInfo.hash = response.data.hash
										this.assetInfo.ticker = response.data.symbol
										console.log('asug')
									}
								}
							} else {
								this.state = 'send'
							}
						})
						.catch((response) => {
						console.log(response.response)
						// if(response.assetKey != "" && response.assetName == "") {
						//   this.showSteps.one = false
						//   this.showCheck.one = true
						//   this.showSteps.two = false
						//   this.showSteps.three = true
						//   this.showCheck.two = true
						//   this.isDisabled.three = false
						// }
						});
          },


	  },
  
	  mounted() {
		  this.checkContract();
		  this.timer();
		  this.getAddress();
	  },
  }
  </script>
  
  <style scoped lang="scss">
  .backdrop {
	  top: 0;
	  right:0;
	  position: fixed;
	  width: 100vw;
	  height: 100vh;
	  z-index: 1;
	  background: rgba(0, 0, 0, 0.6);
  }
  .b-modal {
	  width: 600px;
	  height: auto;
	  margin: 25px auto;
	  background: black;
	  border-radius: 20px;
	  border-color:black;
	  border-width:2px;
	  z-index: 2;
	  overflow-x: hidden !important;
	  color:black;
	  overflow-y: auto;
  }
  
  .modal-header {
	  background-color: black;
	  margin-right:0px;
	  font-size:25px;
	  display:flex;
	  justify-content:space-between;
	  border:none;
  }
  
  .modal-title{
	  margin: 0 auto;
	  color:black;
  }
  
  .modalBody {
	  background-color:black;
	  width:100%;
	  padding:50px;
	  border-radius: 10px;
	  padding-top:35px;
	  padding-bottom:0px;
  }

  .error {
	  color:red;
  }
  
  .buttonWrapper {
	  width:100%;
  }
  
  .x {
	  cursor:pointer;
  }
  .submit {
	  color:gray;
	  background:none;
	  border-color:gray;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:100%;
  }
  
  .cancel {
	  color:orange;
	  background:none;
	  border-color:orange;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:100%;
  
  }

  .copy {
	  color:gray;
	  background:none;
	  border-color:gray;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:40%;
	  font-size:0.8em;
  }

  .upload {
	  color:white;
	  background:none;
	  border-color:white;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:100%;
  }

  .otp-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1em;
      div {
      margin: 0 .25em;

      .otp-input {
              width: 60px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: none;
              box-shadow: none;
              outline: none; 
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 70px;
      }
    }
  }

  
	@media screen and (max-width: 1100px) {
	  
	}

	 @media screen and (max-width: 800px) {
	  
	}
  
	  @media screen and (max-width: 600px) {
			
	  }
  </style>