<template>
  <div>
    <div v-if="!asset.nft">
      <b-card
          img-alt="Image"
          img-top
          tag="article"
          style="width: 20rem;background-color: rgba(199, 199, 199, 0.1); height:25rem;"
          class="m-5 text-white text-center card-wrapper"
      >
        <button class="btn submit mb-2" @click="viewCard">VIEW DETAILS</button>
        <img :src="asset.icon" style="width:10rem; height:10rem;" class="p-3">
        <h2>{{ asset.symbol }}</h2>
        <b-card-text v-if="asset.symbol != 'JCASH'">
          {{ asset.description }}
        </b-card-text>
        <b-card-text v-if="asset.symbol == 'JCASH'">
          JCash.io Asset Token
        </b-card-text>
      </b-card>
    </div>
    <div v-if="asset.nft">
      <b-card
          img-alt="Image"
          img-top
          tag="article"
          style="width: 20rem;background-color: rgba(199, 199, 199, 0.1); height:25rem;"
          class="m-5 p-3 text-white text-center card-wrapper"
      >
        <button class="btn submit mb-2" @click="viewCard">VIEW DETAILS</button>
        <img v-if="nftType == 'mp3'" src="../../assets/mp3.png" style="width:10rem; height:10rem;">
        <img v-if="nftType == 'mp4'" src="../../assets/mp4.png" style="width:10rem; height:10rem;">
        <img v-if="nftType == 'gif'" src="../../assets/gif.png" style="width:10rem; height:10rem;">
        <img v-if="nftType == 'jpeg'" src="../../assets/jpeg.png" style="width:10rem; height:10rem;">
        <img v-if="nftType == 'jpg'" src="../../assets/jpg.png" style="width:10rem; height:10rem;">
        <img v-if="nftType == 'png'" src="../../assets/png.png" style="width:10rem; height:10rem;">
        <h2>{{ asset.symbol }}</h2>
        <b-card-text>
          {{ asset.description }}
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
    props: ["asset", "userAddress"],
    data: function(){
      return {
        nftType: "",
      }
    },
    methods: {
      viewCard() {

        this.asset.symbol = this.asset.symbol.replace(/^\s+|\s+$/gm,'');

        console.log(this.asset.symbol)
        this.$emit('view')
      },
      send() {
        this.$emit('send')
      },
      getNftType() {
        if(this.asset.nft == true) {
          this.nftType = this.asset.media.split('.').pop();
          console.log(this.nftType)
        }
      }
    },
    mounted: function(){
      this.getNftType()
    }
}
</script>

<style scoped>
  .cancel {
	  color:orange;
	  background:none;
	  border-color:orange;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:50%;
  }

  .submit {
	  color:gray;
	  background:none;
	  border-color:gray;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:100%;
  }

  .card-wrapper {
    display:flex !important;
    flex-direction: column  !important;
    justify-content: space-between !important;
    padding:30px !important;
  }
</style>